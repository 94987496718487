import { ReferenceDataKeyEnum } from 'src/app/lcms/enums';
import { RefDataFields } from '../ref-data-fields';

export class Carrier extends RefDataFields {
  public Id: string;
  public Alias: string;
  public Carrier: string;
  public CarrierId: string;
  public Comments: string;
  public CTLogisticCarrier: boolean;
  public MaxMileRange: number;
  public MileageMetricPricing: boolean;
  public MOT: string;
  public SCAC: string;
  public PublishToSAP: boolean;
  public Type: string;
  public EntityType = ReferenceDataKeyEnum.CARRIERS;
  public AccessorialChargeVisible: boolean;
  public BillingCodeEDIVisible: boolean;
  public FullLoadVisible: boolean;
  public SpecificServicePricingVisible: boolean;
  public SplitLoadVisible: boolean;
  public PublishSSPToSAP: boolean;

  constructor(data: any) {
    super(data);
    this.Id = data?.id;
    this.Carrier = data?.Carrier;
    this.Alias = data?.Alias || this.Carrier;
    this.CarrierId = data?.CarrierId;
    this.Comments = data?.Comments;
    this.CTLogisticCarrier = data?.CTLogisticCarrier;
    this.MaxMileRange = data?.MaxMileRange;
    this.MileageMetricPricing = data?.MileageMetricPricing;
    this.MOT = data?.Mot;
    this.SCAC = data?.SCAC;
    this.PublishToSAP = data?.PublishToSAP;
    this.Type = data?.Type;
    this.AccessorialChargeVisible = data?.AccessorialChargeVisible;
    this.BillingCodeEDIVisible = data?.BillingCodeEDIVisible;
    this.FullLoadVisible = data?.FullLoadVisible;
    this.SpecificServicePricingVisible = data?.SpecificServicePricingVisible;
    this.SplitLoadVisible = data?.SplitLoadVisible;
    this.PublishSSPToSAP = data?.PublishSSPToSAP || false;
  }
}
