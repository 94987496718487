import { AuditFields } from './audit-fields.model';

export class ReferenceDataItem extends AuditFields {
  public Id: string;
  public EntityId?: string;
  public Alias: string;
  public Name?: string;
  public Value?: string;
  public Data?: any;
  public MOT?: string;
  public Comments?: string;
  public Type: string;
  public PublishToSAP?: boolean;
  public _etag: string;
  public Disabled?: boolean = false;

  // CARRIER
  public Carrier?: string;
  public CarrierId?: string;
  public CTLogisticCarrier?: boolean;
  public MaxMileRange?: number;
  public MileageMetricPricing?: boolean;
  public SCAC?: string;

  // TERMINAL
  public Terminal?: string;
  public TerminalAddress?: string;
  public TerminalCity?: string;
  public TerminalNumber?: string;
  public TerminalState?: string;
  public TerminalZip?: string;
  public MarketRegion?: string;
  public RetailRegion?: string;
  public SAPPlantNumber?: string;

  // PRODUCT
  public Division?: string;
  public MaterialCode?: string;
  public MaterialCodeDescription?: string;
  public ProductId?: string;
  public ProductName?: string;

  // STATION
  public StationAddress?: string;
  public StationCity?: string;
  public StationName?: string;
  public StationNumber?: string;
  public StationState?: string;
  public StationZip?: string;

  // BILLING CODE
  public BillingEDI: string; // 1st Billing col in excel
  public BillingCTL: string; // 2nd Billing col in excel - SAP Billing Code
  public Description: string;

  // FscRegions
  public FscSeriesId: string;
  public FscSeriesName: string;
  public FscSeriesColor: string;
  public FscSeriesEiaUrl: string;
  public FscSeriesDescription: string;

  // FscSchedule
  public FscFuelIndexBracketLimitStart: number;
  public FscFuelIndexBracketLimitEnd: number;
  public FscRatePerLoadedMile: number;
  public FscRegionPaddName: string; // Showing the Fsc rate selected regions name
  

  constructor(data: any) {
    super(data);
    this.Id = data?.id;
    this.EntityId = data?.EntityId;
    this.Alias = data?.Alias;
    this.Name = data?.Name;
    this.Value = data?.Value;
    this.Data = data?.Data;
    this.MOT = data?.Mot;
    this.Comments = data?.Comments;
    this.Type = data?.Type;
    this.PublishToSAP = data?.PublishToSAP;
    this._etag = data?._etag;

    // CARRIER
    this.Carrier = data?.Carrier;
    this.CarrierId = data?.CarrierId;
    this.CTLogisticCarrier = data?.CTLogisticCarrier;
    this.MaxMileRange = data?.MaxMileRange;
    this.MileageMetricPricing = data?.MileageMetricPricing;
    this.SCAC = data?.SCAC;

    // TERMINAL
    this.MarketRegion = data?.MarketRegion;
    this.RetailRegion = data?.RetailRegion;
    this.SAPPlantNumber = data?.SapPlantNumber;
    this.Terminal = data?.Terminal;
    this.TerminalAddress = data?.TerminalAddress;
    this.TerminalCity = data?.TerminalCity;
    this.TerminalNumber = data?.TerminalNumber;
    this.TerminalState = data?.TerminalState;
    this.TerminalZip = data?.TerminalZip;

    // PRODUCT
    this.MaterialCode = data?.MaterialCode;
    this.MaterialCodeDescription = data?.MaterialCodeDescription;
    this.ProductId = data?.ProductId;
    this.ProductName = data?.ProductName;
    this.Division = data?.Division;

    // STATION
    this.StationAddress = data?.StationAddress;
    this.StationCity = data?.StationCity;
    this.StationName = data?.StationName;
    this.StationNumber = data?.StationNumber;
    this.StationState = data?.StationState;
    this.StationZip = data?.StationZip;

    // BILLING CODE
    this.BillingCTL = data?.BillingCTL;
    this.BillingEDI = data?.BillingEDI;
    this.Description = data?.Description;

    // FscRegions
    this.FscSeriesId = data.FscSeriesId;
    this.FscSeriesName = data.FscSeriesName;
    this.FscSeriesColor = data.FscSeriesColor;
    this.FscSeriesEiaUrl = data.FscSeriesEiaUrl;
    this.FscSeriesDescription = data.FscSeriesDescription;

    // FscSchedule
    this.FscFuelIndexBracketLimitStart = data.FscFuelIndexBracketLimitStart;
    this.FscFuelIndexBracketLimitEnd = data.FscFuelIndexBracketLimitEnd;
    this.FscRatePerLoadedMile = data.FscRatePerLoadedMile;
    this.FscRegionPaddName = data.FscRegionPaddName;

  }
}
