export enum MotEnum {
  TRUCK = 'truck',
}

export enum FilterType {
  CHECKBOX = 'checkbox',
  RADIOBUTTON = 'radiobutton',
  TOGGLE = 'toggle',
}

export enum FilterRefDataType {
  TERMINAL = 'TERMINAL',
  CARRIER = 'CARRIER',
  SCAC = 'SCAC',
  SHIPMENTCOSTINGTOGGLE = 'SHIPMENTCOSTINGTOGGLE',
}

export enum ReferenceDataKeyEnum {
  CARRIERS = 'carrier',
  TERMINALS = 'terminal',
  PRODUCT = 'product',
  STATION = 'station',
  GENERAL = 'general',
  BILLING_CODE = 'billingcode',
  UOM = 'uom',
  FSC_SCHEDULER = "fscschedule",
  FSC_REGIONS = "fscregions"
}

export enum GeneralRefDataKeyEnum {
  DIVISION = 'Division',
  SALES_ORG = 'SalesOrg',
  DISTR_CHANNEL = 'DistrChannel',
  MOT = 'ModeOfTransport',
  SCALE_UOM = 'ScaleUom',
  ZFPF = 'ZFPF',
  ZFP2 = 'ZFP2',
  ACCESSORIAL_CHARGES = 'AccessorialCharges',
  COND_CHARGES = 'CondCurrency',
}

export enum ClrTabLabelEnum {
  FULL_LOAD = 'full load',
  SPLIT_LOAD = 'split load',
  MILEAGE_METRIC_PRICING = 'mileage metric pricing',
  SPECIFIC_SERVICE_PRICING = 'specific service pricing',
  ACCESSORIAL_CHARGE = 'accessorial charges',
  FUEL_SURCHARGE = "fuel surcharge",
}

export enum ClrTabsEnum {
  FULL_LOAD = 'FullLoad',
  SPLIT_LOAD = 'SplitLoad',
  MILEAGE_METRIC_PRICING = 'MileageMetricPricing',
  SPECIFIC_SERVICE_PRICING = 'SpecificServicePricing',
  ACCESSORIAL_CHARGE = 'AccessorialCharge',
  FUEL_SURCHARGE ="Fuel Surcharge",
}

export enum DmtTabsEnum {
  ZFPF = 'FullLoad',
  ZFP2 = 'SplitLoad',
}

export enum MessagesEnum {
  INVALID_MILE_RANGE = 'Please enter a valid mile range.',
  INVALID_BASE_RATE = 'Base rate should be a number greater than 0.',
  INVALID_STOP_OFF = 'Stop-off should be a number greater than 0',
  REFRESH_CONFIRM_MSG = 'There are unsaved changes on the grid. Do you want to proceed with refresh?',
  RESET_CONFIRM_MSG = 'All changes will be reset. Do you wish to proceed?',
  INVALID_EFFECTIVEDATE = 'Please enter a valid Effective Date.',
  NO_UNSAVED_CHANGES = 'There are no unsaved changes.',
  MILE_RANGE_REQD = 'Please enter a mile range first.',
  DELETE_NON_EMPTY_MILE_RANGE = 'One or more terminals have non-empty rates. Do you wish to delete the mile range anyway?',
  INVALID_STOP_OFF_FIELD = 'Stop-off value should be a number greater than or equal to 0.',
  CONFIRM_0_STOP_OFF = 'Are you sure you want to set stop-off as 0?',
  SOMETHING_WENT_WRONG = 'Something went wrong, please try again!',
  SUCCESS_CLR_UPDATE = 'Carrier lane rates added successfully.',
  WARNING_CONFIRM_MSG = 'There are some warnings in data. Do you still wish to save?',
  UNABLE_TO_READ_FILE = 'Unable to read file',
  UPLOAD_ALL_SHEETS_MISSING = 'Uploaded excel should have at least one of the following sheets - <b> <SHEETS> </b>.',
  UPLOAD_FEW_SHEETS_MISSING = 'Uploaded excel does not have following sheet(s) - <b> <SHEETS> </b>.',
  UPLOAD_NO_SHEET_SELECTED = 'Please select sheet(s) to continue',
  CARRIER_NAME_MISMATCH = 'Carrier Name does not match with the currently selected carrier.',
  SCAC_MISMATCH = 'SCAC does not match with the currently selected carrier.',
  ERROR_WARNING_UPLOAD = 'There are some Error/Warnings in data. Please check',
  SAVE_CHANGES_TO_CONTINUE = 'Please save the changes before proceeding.',
  INVALID_DATE = 'Please select a valid date.',
  UNSAVED_CHANGES_WARNING = 'There are some unsaved changes. Do you want to proceed without saving?',
  SUCCESS_CLR_PUBLISH = 'Carrier lane rates publish request sent successfully.',
  VERSION_DUPLICATE = 'Version Name already exists for this Carrier.',
  VERSION_NAME_REQD = 'Version Name is required.',
  VERSION_NAME_MAXLENGTH = 'Version Name can be max 40 characters.',
  NO_FILE_SELECTED = 'Please select a file to continue.',
  INVALID_CLR_EXCEL = 'Invalid excel format! <ERROR>',
  CONFIRM_DELETE_VERSION = 'Are you sure you want to delete version',
  SUCCESS_VERSION_DELETE = 'Version deleted successfully.',
  FAILURE_VERSION_DELETE = 'Version could not be deleted.',
  SUCCESS_VERSION_UPDATE = 'Version updated successfully.',
  FAILURE_VERSION_UPDATE = 'Version could not be updated.',
  LIVE_VERSION_EXISTS = 'Draft Version already exists for this Carrier. Do you wish to override?',
  FAILURE_SAP_TXT_FILE = 'Unable to download SAP file.',
  CANNOT_DELETE_ALL_MILE_RANGES = 'Cannot delete last mile range. A lane rate should have at least one mile range',
  SAME_FROM_TO_TERMINAL = 'From and To Terminal cannot be same.',
  LIVE_VERSION_WARNING = 'After copying, only if the specific Terminal is visible in the Draft version and have some data, it will be displayed.',
  USER_VERSION_WARNING = 'After copying, only if the specific Terminal have some data, it will be displayed.',
  FAILURE_CLR_TEMPLATE = 'Unable to fetch lane rates template excel.',
  VERSION_NOT_FOUND = 'Version not found! Please refresh the page.',
  SUCCESS_MAPPING_UPDATE = 'Mapping(s) updated successfully.',
  SUCCESS_MAPPING_REMOVE = 'Mapping removed successfully.',
  CONFIRM_REMOVE_MAPPING = 'Are you sure you want to remove existing mapping?',
  TERMINAL_REQD_FOR_MAPPING = 'Terminal Number cannot be empty for a terminal mapped to a carrier.',
  ERROR_MAPPING_ALREADY_EXISTS = 'Mapping already exists. Cannot add duplicate mapping.',
  INVALID_INDEX_FUEL_PRICE ="Fuel price should be a number greater than 0."
}

export enum SnackBarActionsEnum {
  OKAY = 'Okay',
  CONFIRM = 'Confirm',
  YES = 'Yes',
  NO = 'No',
  X = 'X',
}

export enum SnackBarPanelClassEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum MicroServiceType {
  LCMS_BFF = 'LCMS_BFF',
}

export enum GridRowMenuIdEnum {
  DELETE_MILE_RANGE = 'deleteMileRange',
  ADD_MILE_RANGE_ABOVE = 'addMileRangeAbove',
  ADD_MILE_RANGE_BELOW = 'addMileRangeBelow',
  ADD_MAPPING = 'addMapping',
  REMOVE_MAPPING = 'removeMapping',
}

export enum DialogBtnTextEnum {
  YES = 'Yes',
  NO = 'No',
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel',
  SAVE = 'Save',
  PUBLISH = 'Publish',
  CREATE = 'Create',
}

export enum DialogHeaderTextEnum {
  CONFIRM_DELETE_MILE_RANGE = 'Delete Mile Range?',
}

export enum AgGridColumnTypeEnum {
  NUMBER_COLUMN = 'numberColumn',
  FORMATTED_NUMBER_COLUMN_0 = 'formattedNumberColumn_0',
  FORMATTED_NUMBER_COLUMN_2 = 'formattedNumberColumn_2',
  FORMATTED_NUMBER_COLUMN_4 = 'formattedNumberColumn_4',
  DOLLAR_CURRENCY_COLUMN = 'dollarCurrencyColumn',
  DOLLAR_CURRENCY_COLUMN_3 = 'dollarCurrencyColumn_3',
  TEXT_COLUMN = 'textColumn',
  TEXTAREA_COLUMN = 'textareaColumn',
  DATE_COLUMN = 'dateColumn',
  DATE_TIME_COLUMN = 'dateTimeColumn',
  LIST_COLUMN = 'listColumn',
  CHECKBOX_COLUMN = 'checkboxColumn',
}

export enum AgGridColumnFilterTypeEnum {
  NUMBER_COLUMN_FILTER = 'agNumberColumnFilter',
  TEXT_COLUMN_FILTER = 'agTextColumnFilter',
  DATE_COLUMN_FILTER = 'agDateColumnFilter',
  DATE_TIME_COLUMN_FILTER = 'agDateTimeColumnFilter',
  SET_COLUMN_FILTER = 'agSetColumnFilter',
  CHECKBOX_COLUMN_FILTER = 'checkboxColumnFilter',
}
export enum LCMSAccessTypes {
  CLR_RO = 'CLR_RO',
  CLR_RW = 'CLR_RW',
  RATEMASTER_RO = 'RATEMASTER_RO',
  RATEMASTER_RW = 'RATEMASTER_RW',
  REFDATA_RO = 'REFDATA_RO',
  REFDATA_RW = 'REFDATA_RW',
  LCMS_TRUCK_USER = 'LCMS_TRUCK_USER',
  LCMS_APP_USER = 'LCMS_APP_USER',
}

export enum Roles {
  LaneRate_User_ReadOnly = 'LaneRate.User.ReadOnly',
  LaneRate_User_ReadWrite = 'LaneRate.User.ReadWrite',
  RateMaster_User_ReadOnly = 'RateMaster.User.ReadOnly',
  RateMaster_User_ReadWrite = 'RateMaster.User.ReadWrite',
  RefData_User_ReadOnly = 'RefData.User.ReadOnly',
  RefData_User_ReadWrite = 'RefData.User.ReadWrite',
}

export enum ValidationType {
  Info = 1,
  Warning = 2,
  Error = 3,
}

export enum ScreenType {
  None = 'common',
  FullLoad = 'full load',
  SplitLoad = 'split load',
  SpecificServicePricing = 'specific service pricing',
  MileageMetricPricing = 'mileage metric pricing',
  AccessorialCharge = 'accessorial charges',
  FuelSurcharge = 'fuel surcharge',
}

export enum MileRangeErrorMessages {
  NO_RANGE = 'No Mile Ranges found. Please add Mile Ranges.',
  INITIAL_RANGE_0 = 'Initial Mile Range should start with 0. Range is - <Range>',
  INVALID_RANGE = 'End range should be greater than or equal start range. Range is - <Range>',
  OVERLAPPING_RANGE = 'Overlapping Mile ranges. Ranges are - <Range1>, <Range2>',
  DISCONTINUOUS_RANGE = 'Discontinuous Mile ranges. Ranges are - <Range1>, <Range2>',
  DUPLICATE_RANGE = 'Duplicate Mile ranges. Ranges are - <Range>',
  EMPTY_RANGE = 'Empty Mile range found. Below Range - <Range>',
}

export enum LaneRateErrorMessages {
  BLANK_VALUES_IN_MIDDLE = 'There are some Blank rates in middle. Terminal - <Terminal>',
  DATE_MISSING = 'Effective Date missing. Terminal - <Terminal>',
  ZERO_VALUE = 'Rate cannot be 0. Terminal - <Terminal>, Range - <Range>',
  LINEARLY_INCREASING_VALUE = 'Rates should be linearly increasing. Terminal - <Terminal>, Range - <Range>',
  THRESHOLD_VALUE = 'Rate cannot be greater than $10000. Terminal - <Terminal>, Range - <Range>',
  NEGATIVE_VALUE = 'Rate cannot be negative. Terminal - <Terminal>, Range - <Range>',
  BLANK_VALUES_IN_END = 'There are some Blank rates. Terminal - <Terminal>',
  NO_RATES = 'No Rates found. Please add Rates. Terminal - <Terminal>',
  INVALID_ONEWAY_MILE = 'One way mile should be greater than 0. From - <FromTerminal>, To - <ToTerminal>, Product - <Product>',
  INVALID_RATE_PERLOAD = 'Rate per load should be greater than 0. From - <FromTerminal>, To - <ToTerminal>, Product - <Product>',
  DUPLICATE_SSP = 'Duplicate Records found. From - <FromTerminal>, To - <ToTerminal>, One Way Miles - <OneWayMiles>, Product - <Product>',
  MANDATORY_FIELDS = 'Following mandatory field(s) are missing - <Fields>.',
}
export enum FscErrorMessages {
  MANDATORY_FIELDS = 'Following mandatory field(s) are missing - <Fields>.',
  INVALID_RATE = 'Rate should be greater than 0.',
}

export const NonProdADGroupsForAccess = [
  //'8df061c8-24b4-411f-a37f-268b74af8b48',
  //'7702d385-3675-4731-96c0-b5880c2270d5',
  // '42970893-627b-44a9-800c-7c0d194c2042',
  //'49924b47-51ec-4300-bbba-2cdc29536eb8'

  'AZ-CSCM-LCMS-Procurement-RO-NonProd',
  'AZ-CSCM-LCMS-Procurement-RW-NonProd',
  'AZ-CSCM-LCMS-RoadTransport-RO-NonProd',
  'AZ-CSCM-LCMS-RoadTransport-RW-NonProd',
];

export const ProdADGroupsForAccess = [
  'AZ-CSCM-LCMS-Procurement-RO-Prod',
  'AZ-CSCM-LCMS-Procurement-RW-Prod',
  'AZ-CSCM-LCMS-RoadTransport-RO-Prod',
  'AZ-CSCM-LCMS-RoadTransport-RW-Prod',
];

export const AccessToRoleMapping = [
  {
    Access: LCMSAccessTypes.CLR_RO,
    RequiredRoles: [
      Roles.LaneRate_User_ReadOnly,
      Roles.LaneRate_User_ReadWrite,
    ],
  },
  {
    Access: LCMSAccessTypes.CLR_RW,
    RequiredRoles: [Roles.LaneRate_User_ReadWrite],
  },
  {
    Access: LCMSAccessTypes.RATEMASTER_RO,
    RequiredRoles: [
      Roles.RateMaster_User_ReadOnly,
      Roles.RateMaster_User_ReadWrite,
    ],
  },
  {
    Access: LCMSAccessTypes.RATEMASTER_RW,
    RequiredRoles: [Roles.RateMaster_User_ReadWrite],
  },
  {
    Access: LCMSAccessTypes.REFDATA_RO,
    RequiredRoles: [Roles.RefData_User_ReadOnly, Roles.RefData_User_ReadWrite],
  },
  {
    Access: LCMSAccessTypes.REFDATA_RW,
    RequiredRoles: [Roles.RefData_User_ReadWrite],
  },
  {
    Access: LCMSAccessTypes.LCMS_TRUCK_USER,
    RequiredRoles: [
      Roles.RefData_User_ReadOnly,
      Roles.RefData_User_ReadWrite,
      Roles.RateMaster_User_ReadOnly,
      Roles.RateMaster_User_ReadWrite,
      Roles.LaneRate_User_ReadOnly,
      Roles.LaneRate_User_ReadWrite,
    ],
  },
  {
    Access: LCMSAccessTypes.LCMS_APP_USER,
    RequiredRoles: [
      Roles.RefData_User_ReadOnly,
      Roles.RefData_User_ReadWrite,
      Roles.RateMaster_User_ReadOnly,
      Roles.RateMaster_User_ReadWrite,
      Roles.LaneRate_User_ReadOnly,
      Roles.LaneRate_User_ReadWrite,
    ],
  },
];

export enum VersionTypeEnum {
  Undefined = 'Undefined',
  Live = 'Live',
  Draft = 'Draft',
  Published = 'Published',
}

export enum PublishStatusEnum {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Published = 'Published',
  Failed = 'Failed',
}

export enum FileTypeEnum {
  EXCEL = 'Excel',
  TEXT = 'Text',
  ZIP = 'Zip',
}

export enum ConditionTypeEnum {
  ZFPF = 'FullLoad',
  ZFP2 = 'SplitLoad',
}

export enum VersionPopupModeEnum {
  VIEW = 'View',
  EDIT = 'Edit',
}

export enum LocalStorageKeyEnum {
  CARRIER_ID = 'carrierId',
}

export enum ClrExcelSheetsEnum {
  FULL_LOAD = 'Mileage Zone Pricing-Full Load',
  SPLIT_LOAD = 'Mileage Zone Pricing-Split Load',
  MILEAGE_METRIC_PRICING = 'Mileage Metric Pricing',
  SPECIFIC_SERVICE_PRICING = 'Specific Service Pricing',
  ACCESSORIAL_CHARGES = 'Accessorial Charges',
  FUEL_SURCHARGE ="Chevron FSC",
}
