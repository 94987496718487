export const environment = {
  production: false,
  clientId: '2bc5c284-0f74-48a4-8209-449906c3efaf',
  LCMS_BFF_tokenEndpoint:
    'https://lcms-test.azure.chevron.com/ratedatabffsvc/user_impersonation',
  calRedirectURI: 'https://lcms-dev.azure.chevron.com',
  LCMSTruckAPI:
    'https://cscm-lcms-dev-ratedata-bff-af-cvx.azurewebsites.net/api',
  appInsights: {
    instrumentationKey: '06f4e608-e348-4303-ac0c-18ed57a4802c',
  },
};
